<template>
  <div class="icon">
    <i class="pi pi-tag"></i>
  </div>
</template>

<script setup></script>

<style scoped>
.icon {
  cursor: pointer;
  font-size: 1.5rem;
}
</style>
