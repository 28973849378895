<template>
  <Header />
  <div class="content">
    <Sidebar v-if="isAuthenticated" />
    <router-view />
  </div>
</template>

<script setup>
import { watch } from "vue";
import Header from "./components/HeaderComp.vue";
import Sidebar from "./components/SidebarComp.vue";
import { useAuthStore } from "./hooks/useAuthStore";
import { useDeviceLocation } from "./hooks/useDeviceLocation";
import { useInvitationStore } from "./hooks/useInvitationStore";
import { useEventSource } from "./hooks/useSSEConnection";

useEventSource();
const { isAuthenticated } = useAuthStore();
const { startLocationTracking, stopLocationTracking } = useDeviceLocation();
const { startInviteLoop, stopInviteLoop } = useInvitationStore();

watch(
  isAuthenticated,
  () => {
    if (isAuthenticated.value) {
      startLocationTracking();
      startInviteLoop();
    } else {
      stopLocationTracking();
      stopInviteLoop();
    }
  },
  { immediate: true }
);
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.content {
  position: relative;
  display: flex;
  flex-grow: 1;
}
</style>
