<template>
  <div class="flex flex-1 justify-content-center min-h-screen">
    <div class="surface-card p-4 shadow-2 border-round w-8 md:w-6 lg:w-4">
      <h2 class="text-center">Change Password</h2>
      <form @submit.prevent="handleChangePassword" class="p-fluid">
        <div class="field">
          <label for="currentPassword">Current Password</label>
          <Password
            id="currentPassword"
            v-model="currentPassword"
            placeholder="Current Password"
            toggleMask
            required
            class="w-full"
            :feedback="false"
            inputClass="w-full"
          />
        </div>
        <div class="field">
          <label for="newPassword">New Password</label>
          <Password
            id="newPassword"
            v-model="newPassword"
            placeholder="New Password"
            toggleMask
            required
            class="w-full"
            :feedback="true"
            inputClass="w-full"
          />
        </div>
        <div class="field">
          <label for="confirmPassword">Confirm New Password</label>
          <Password
            id="confirmPassword"
            v-model="confirmPassword"
            placeholder="Confirm New Password"
            toggleMask
            required
            class="w-full"
            :feedback="false"
            inputClass="w-full"
          />
        </div>
        <Button
          type="submit"
          label="Change Password"
          icon="pi pi-check"
          class="w-full mt-3"
          :disabled="isSubmitting || !isFormValid"
        />
      </form>
      <div v-if="errorMessage" class="mt-3 text-center text-red-600">
        {{ errorMessage }}
      </div>
      <div v-if="successMessage" class="mt-3 text-center text-green-600">
        {{ successMessage }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import Password from "primevue/password";
import Button from "primevue/button";
import api from "../utils/api.js"; // Assuming you have a utility for API calls
import { useSidebarState } from "../hooks/useSidebarState";

const { isSidebarOpen, toggleSidebar } = useSidebarState();
const currentPassword = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const errorMessage = ref("");
const successMessage = ref("");
const isSubmitting = ref(false);

const isFormValid = computed(() => {
  return (
    newPassword.value &&
    confirmPassword.value &&
    newPassword.value === confirmPassword.value
  );
});

const handleChangePassword = async () => {
  errorMessage.value = "";
  successMessage.value = "";
  isSubmitting.value = true;

  try {
    await api.put("/auth/change-password", {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
    });
    successMessage.value = "Password changed successfully.";
    currentPassword.value = "";
    newPassword.value = "";
    confirmPassword.value = "";
  } catch (error) {
    errorMessage.value =
      error.response?.data?.error ||
      "Failed to change password. Please try again.";
  } finally {
    isSubmitting.value = false;
  }
};
let wasOpened = false;
onMounted(() => {
  wasOpened = isSidebarOpen.value;
  if (wasOpened) {
    toggleSidebar();
  }
});
onUnmounted(() => {
  if (wasOpened && !isSidebarOpen.value) {
    toggleSidebar();
  }
});
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
}

.surface-card {
  background-color: white;
}
</style>
