import { ref, computed } from "vue";
import api from "../utils/api";
import store from "../store";

export function useCollaboratorStore(noteId) {
  const isLoading = ref(false);

  const sharedUsers = computed(
    () => store.state.notes?.find((note) => note.id === noteId)?.collaborators
  );
  const tmpSharedUsers = ref([]);

  async function fetchSharedUsers() {
    try {
      if (noteId) {
        isLoading.value = true;
        const response = await api.get(`/api/notes/${noteId}/collaborators`);
        const note = store.state.notes?.find((note) => note.id === noteId);
        if (note) {
          store.mutations.updateModel("notes", noteId, {
            ...note,
            collaborators: response.data,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching shared users:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function addSharedUser(noteId, userData) {
    try {
      if (noteId) {
        const response = await api.post(
          `/api/notes/${noteId}/collaborators`,
          userData
        );
        return response.data;
      } else {
        tmpSharedUsers.value.push({
          id: `TMP-${new Date().getTime()}`,
          ...userData,
        });
      }
    } catch (error) {
      console.error("Error adding shared user:", error);
      throw error;
    }
  }
  async function updateSharedUser(noteId, userId, userData) {
    try {
      if (noteId) {
        const response = await api.put(
          `/api/notes/${noteId}/collaborators/${userId}`,
          userData
        );
        const note = store.state.notes?.find((note) => note.id === noteId);
        if (note) {
          const index = note?.collaborators
            ?.map((t) => t.id)
            ?.indexOf(response.data.id);
          if (index >= 0) {
            const updatedCol = [...note.tasks];
            updatedCol[index] = response.data;
            store.mutations.updateModel("notes", noteId, {
              ...note,
              collaborators: updatedCol,
            });
          }
        }
      } else {
        const index = tmpSharedUsers.value?.map((t) => t.id)?.indexOf(userId);
        if (index >= 0) {
          tmpSharedUsers.value[index] = {
            ...tmpSharedUsers.value[index],
            ...userData,
          };
        }
      }
    } catch (error) {
      console.error("Error removing shared user:", error);
      throw error;
    }
  }
  async function removeSharedUser(noteId, userId) {
    try {
      if (noteId) {
        await api.delete(`/api/notes/${noteId}/collaborators/${userId}`);
        const note = store.state.notes?.find((note) => note.id === noteId);
        if (note) {
          store.mutations.updateModel("notes", noteId, {
            ...note,
            collaborators: note.collaborators.filter((t) => t.id !== userId),
          });
        }
      } else {
        const index = tmpSharedUsers.value?.map((t) => t.id)?.indexOf(userId);
        if (index >= 0) {
          tmpSharedUsers.value.splice(index, 1);
        }
      }
    } catch (error) {
      console.error("Error removing shared user:", error);
      throw error;
    }
  }

  async function fetchUserSuggestions(query) {
    try {
      const response = await api.get("/api/users/search", {
        params: { q: query },
      });
      return response.data;
    } catch (error) {
      console.error("Error searching users:", error);
      throw error;
    }
  }

  return {
    sharedUsers: noteId ? sharedUsers : tmpSharedUsers,
    isLoading,
    fetchSharedUsers,
    addSharedUser,
    updateSharedUser,
    removeSharedUser,
    fetchUserSuggestions,
  };
}
