<template>
  <div
    class="flex justify-content-center align-items-center min-h-screen register"
  >
    <div
      v-if="!successMessage"
      class="surface-card p-4 shadow-2 border-round text-left"
    >
      <h2 class="text-center">Register</h2>
      <form @submit.prevent="register" class="p-fluid" autocomplete="off">
        <div class="field">
          <label for="u-xyz">Username</label>
          <InputText
            id="u-xyz"
            v-model="username"
            placeholder="Username"
            required
            class="w-full"
            autocomplete="off"
          />
        </div>
        <div class="field">
          <label for="e-abc">Email</label>
          <InputText
            id="e-abc"
            v-model="email"
            type="email"
            placeholder="Email"
            required
            class="w-full"
            autocomplete="off"
            @blur="validateEmail"
          />
          <!-- Display email validation error -->
          <small v-if="!isEmailValid && emailTouched" class="p-error"
            >Invalid email address</small
          >
        </div>
        <div class="field">
          <label for="p-qwe">Password</label>
          <Password
            id="p-qwe"
            v-model="password"
            placeholder="Password"
            toggleMask
            required
            :feedback="false"
            class="w-full"
            inputClass="w-full"
            autocomplete="off"
          />
        </div>
        <div class="field">
          <label for="cp-rst">Confirm Password</label>
          <Password
            id="cp-rst"
            v-model="confirmPassword"
            placeholder="Confirm Password"
            toggleMask
            required
            :feedback="false"
            class="w-full"
            inputClass="w-full"
            autocomplete="off"
          />
        </div>

        <!-- Legal Copy and Checkbox for Agreement -->
        <div class="field-checkbox mt-3">
          <Checkbox
            v-model="agreedToTerms"
            required
            binary
            inputId="agreement-checkbox"
          />
          <label for="agreement-checkbox" class="ml-2">
            I have read and agree to the
            <router-link to="/terms-of-service">Terms of Service</router-link>
            and
            <router-link to="/privacy-policy">Privacy Policy</router-link>.
          </label>
        </div>

        <div v-if="errorMessage" class="mt-3 text-center text-error">
          {{ errorMessage }}
        </div>
        <Button
          type="submit"
          label="Register"
          icon="pi pi-user-plus"
          class="w-full mt-3"
          :disabled="isFormInvalid"
        />
      </form>
      <router-link to="/login" class="block mt-3 text-center"
        >Already have an account? Login</router-link
      >
    </div>

    <div v-else class="surface-card p-4 shadow-2 border-round text-left">
      <h2 class="text-center">Registration Successful</h2>
      <p class="text-center">
        Thank you for registering! <br />
        A verification email has been sent to <strong>{{ email }}</strong
        >. Please check your inbox and follow the instructions to verify your
        email address to login.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import api from "../utils/api.js";

// Form fields
const username = ref("");
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const agreedToTerms = ref(false); // New variable for agreement checkbox
const emailTouched = ref(false); // Track if email field has been touched

// Error and success state
const errorMessage = ref("");
const successMessage = ref(false);

// Custom email validation
const isEmailValid = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern
  return emailPattern.test(email.value);
});

// Email validation logic
const validateEmail = () => {
  emailTouched.value = true;
};

// Computed property to disable the form submission when the form is invalid
const isFormInvalid = computed(() => {
  return (
    password.value !== confirmPassword.value ||
    !password.value ||
    !email.value ||
    !username.value ||
    !agreedToTerms.value || // Ensure checkbox is checked
    !isEmailValid.value // Ensure email is valid
  );
});

// Register function
const register = async () => {
  if (isFormInvalid.value) {
    errorMessage.value =
      "Passwords do not match, invalid email, or required fields are missing.";
    return;
  }

  try {
    await api.post("/auth/register", {
      username: username.value,
      email: email.value,
      password: password.value,
    });
    successMessage.value = true;
  } catch (error) {
    errorMessage.value = "Registration failed. Please try again.";
  }
};
</script>

<style scoped>
.register {
  margin: 0 auto;
  width: 350px;
  max-width: 98%;
}
.min-h-screen {
  min-height: 100vh;
}

.surface-card {
  background-color: white;
}

.text-error {
  color: red;
}

.p-error {
  color: red;
  font-size: 0.875rem;
}
</style>
