<template>
  <NoteComp
    v-if="noteModel"
    :note="noteModel"
    :preview="true"
    :readOnly="readOnly"
  />
</template>
<script setup>
import { ref, defineProps, watch, toRefs, computed } from "vue";
import NoteComp from "../components/NoteComp";
import { useNoteStore } from "../hooks/useNoteStore";

const { fetchNoteById, fetchNoteByIdAndToken, notes } = useNoteStore();
const props = defineProps({
  id: String,
  preview: { type: Boolean, default: false },
  token: String,
  readOnly: Boolean,
});
const { id, preview, token, readOnly } = toRefs(props);

const note = ref();

const noteModel = computed(() =>
  preview.value ? note.value : notes.value?.find((n) => n.id === id.value)
);

watch(
  id,
  () => {
    note.value = null;
    const fn = async () => {
      console.log("fetchNoteById");
      if (!token.value) {
        const res = await fetchNoteById(id.value, {
          lazy: true,
          skipMutations: preview.value,
        });
        console.log("res", res);
        note.value = res;
      } else {
        const res = await fetchNoteByIdAndToken(id.value, token.value);
        note.value = res;
      }
    };
    fn();
  },
  { immediate: true }
);
</script>
