import { reactive, readonly, watch } from "vue";
let sessionState;
try {
  sessionState = JSON.parse(window?.sessionStorage.getItem("state")) || {};
} catch (e) {
  console.log("e", e);
}

// Initialize state with all model entries
const state = reactive({
  user: null,
  isAuthenticated: false,

  token: null,
  refreshToken: null,
  labels: [],
  users: [],
  notes: [],
  roles: [],
  events: [],
  tasks: [],
  // noteLabels: [],
  fileMetadata: [],
  comments: [],
  reminders: [],
  collaborators: [],
  locations: [],
  checkinAlerts: [],

  // APP UI
  filters: {
    search: "",
    sort: "created_at_desc",
    toggles: {
      hasFile: null,
      hasReminder: null,
      hasTask: null,
      pinned: null,
    },
    labels: [],
    owner: null,
    dateRange: null,
    colors: [],
  },
  invites: [],
  isSidebarOpen: window.innerWidth > 767,
  ...sessionState,
});

watch(
  () => ({
    refreshToken: state.refreshToken,
    user: state.user,
    token: state.token,
    isAuthenticated: state.isAuthenticated,
  }),
  () => {
    window?.sessionStorage.setItem(
      "state",
      JSON.stringify({
        user: state.user,
        token: state.token,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
      })
    );
  }
);

const methods = {
  setUser(user) {
    state.user = user;
    state.isAuthenticated = !!user;
  },
  setToken(token) {
    state.token = token;
  },
  setRefreshToken(token) {
    state.refreshToken = token;
  },
  clearAuth() {
    state.user = null;
    state.token = null;
    state.refreshToken = null;
    state.isAuthenticated = false;
  },
  addModel(type, data) {
    state[type].push(data);
  },
  updateModel(type, id, updatedData) {
    const collection = state[type];
    if (collection && typeof collection[id] !== "undefined") {
      collection[id] = updatedData;
    } else {
      const index = collection?.findIndex((item) => item.id === id);
      if (index !== -1 && state[type]) {
        state[type][index] = updatedData;
      } else {
        console.log(`could not update ${type} ${index} ${collection} ${id}`);
      }
    }
  },
  removeModel(type, id) {
    state[type] = state[type].filter((item) => item.id !== id);
  },

  setState(type, value) {
    state[type] = value;
  },
};

export default {
  state: readonly(state),
  mutations: methods,
};
