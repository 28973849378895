<template>
  <div class="sub-panel">
    <p class="sub-panel-title">Pick date & time</p>
    <DatePicker class="w-full" v-model="localSelectedDate" format="dd/mm/yy" />
    <div class="relative">
      <InputText
        v-model="localSelectedTime"
        class="w-full time-picker input"
        @focus="showTimeOptions"
        @click="showTimeOptions"
        @blur="hideTimeOptions"
        @keydown.down.prevent="navigateOptions('down')"
        @keydown.up.prevent="navigateOptions('up')"
        @keydown.enter.prevent="selectHighlightedOption"
      />
      <ul v-if="showOptions" class="options-list" @mousedown.prevent>
        <li
          v-for="(option, index) in timeOptions"
          :key="option.value"
          @click="selectOption(option.value)"
          :class="{
            //selected: isSelected(option.value),
            selected: index === selectedTimeOptionIndex,
            highlighted: highlightedIndex === index,
          }"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
    <Select
      v-model="localRepeatFrequency"
      :options="repeatOptions"
      optionLabel="label"
      optionValue="value"
      @change="handleRepeatChange"
      class="w-full text-left"
    >
      <template #value v-if="hasCustomRepeat">
        <span class="repeat-msg"> Repeats: {{ repeatSummary }} </span>
      </template>
    </Select>

    <div class="w-full flex gap-1 mt-2">
      <Button
        label="Back"
        text
        icon="pi pi-arrow-left"
        class="flex-1 justify-content-start"
        @click="
          (e) => {
            e.stopPropagation();
            emit('showPanel', 'main');
          }
        "
      />
      <Button label="Save" class="flex-1" icon="pi pi-check" @click="save" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineEmits, defineProps, nextTick } from "vue";
import DatePicker from "primevue/datepicker";
import Select from "primevue/select";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { generateRRule, getRRuleText } from "../../utils/helpers"; // Import RRule utility functions

const props = defineProps({
  selectedDate: Date,
  selectedTime: String,
  repeatFrequency: [String, Number],
  interval: { type: Number, default: 1 },
  frequency: { type: Number, default: 1 },
  endCondition: String,
  endAfterOccurrences: Number,
  endOnDate: Date,
  timeOptions: Array,
  repeatOptions: Array,
});

const emit = defineEmits([
  "update:selectedDate",
  "update:selectedTime",
  "update:repeatFrequency",
  "update:frequency",
  "update:interval",
  "clearRepeat",
  "showPanel",
  "save",
]);

const localSelectedDate = ref(props.selectedDate);
const localSelectedTime = ref(props.selectedTime);
const localRepeatFrequency = ref(props.repeatFrequency || 0); // Ensure a default value
const localEndCondition = ref(props.endCondition || "never"); // Default to "never"
const localEndAfterOccurrences = ref(props.endAfterOccurrences || 1); // Default to 1 occurrence
const localEndOnDate = ref(props.endOnDate || null); // Default to null (no end date)

const localFrequency = ref(props.frequency || 1);
const localInterval = ref(props.interval || 1);

// State to track if options should be shown
const showOptions = ref(false);
const highlightedIndex = ref(-1); // To track the currently highlighted option

const validateTimeInput = (val) =>
  /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/.test(val);

// Watchers for props
watch(localSelectedDate, (newDate) => emit("update:selectedDate", newDate));
watch(localSelectedTime, (newTime) => {
  if (validateTimeInput(newTime)) {
    emit("update:selectedTime", newTime);
  }
});
watch(localRepeatFrequency, (newFrequency) => {
  if (newFrequency !== "custom") {
    emit("clearRepeat");
    nextTick(() => {
      emit("update:interval", 1);
      localFrequency.value = newFrequency;
      emit("update:frequency", newFrequency);
      localInterval.value = 1;
    });
  }
  emit("update:repeatFrequency", newFrequency);
});

const selectedTimeOptionIndex = computed(() => {
  let idx = props.timeOptions?.findIndex(
    (t) => t.value === localSelectedTime?.value
  );
  return idx < 0 ? props.timeOptions.length - 1 : idx;
});

const hasCustomRepeat = computed(() => localRepeatFrequency?.value !== 0);

// Computed property to generate the RRule and its description
const repeatSummary = computed(() => {
  if (!hasCustomRepeat.value) {
    return "";
  }

  // Ensure all required fields have valid values
  const rule = generateRRule({
    frequency: localFrequency?.value ?? 1,
    interval: localInterval.value ?? 1,
    endCondition: localEndCondition.value,
    endAfterOccurrences: localEndAfterOccurrences.value,
    endOnDate: localEndOnDate.value,
  });

  return getRRuleText(rule);
});

// Show time options
const showTimeOptions = () => {
  showOptions.value = true;
  highlightedIndex.value = selectedTimeOptionIndex.value; // Reset the highlighted index when options are shown
};

// Hide time options
const hideTimeOptions = () => {
  if (!validateTimeInput(localSelectedTime.value)) {
    localSelectedTime.value = props.selectedTime;
  }
  nextTick(() => {
    showOptions.value = false;
  });
};

// Select an option
const selectOption = (value) => {
  if (value !== "custom") {
    localSelectedTime.value = value;
  } else {
    localSelectedTime.value = getRoundedTime();
  }
  hideTimeOptions();
  focusTimeInput();
};

// Utility function to round time
function getRoundedTime() {
  const now = new Date();
  now.setMinutes(now.getMinutes() + 30);
  now.setMinutes(30 * Math.floor(now.getMinutes() / 30));
  return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

// Focus the time input
const focusTimeInput = () => {
  nextTick(() => {
    const input = document.querySelector(".time-picker input");
    input?.focus();
  });
};

// Handle repeat changes
const handleRepeatChange = ({ value }) => {
  if (value === "custom") {
    nextTick(() => {
      emit("showPanel", "repeat");
    });
  }
};

// Save changes
const save = () => {
  emit("save");
};

// Navigate through the options using arrow keys
const navigateOptions = (direction) => {
  if (!showOptions.value) return;

  if (direction === "down") {
    highlightedIndex.value =
      (highlightedIndex.value + 1) % props.timeOptions.length;
  } else if (direction === "up") {
    highlightedIndex.value =
      (highlightedIndex.value - 1 + props.timeOptions.length) %
      props.timeOptions.length;
  }
};

// Select the highlighted option when pressing Enter
const selectHighlightedOption = () => {
  if (showOptions.value === true) {
    if (highlightedIndex.value >= 0) {
      selectOption(props.timeOptions[highlightedIndex.value].value);
    }
  } else {
    showTimeOptions();
  }
};

// Check if an option is selected
// const isSelected = (value) => {
//   return (
//     value === localSelectedTime.value ||
//     (hasCustomTime.value && value === "custom")
//   );
// };
</script>

<style>
.options-list {
  position: absolute;
  width: 100%;
  background: var(--corp-select-overlay-background);
  color: var(--corp-select-overlay-color);
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.options-list li {
  padding: 8px 12px;
  cursor: pointer;
}

.options-list li.selected {
  background: var(--corp-select-option-selected-background);
  color: var(--corp-select-option-selected-color);
}

.options-list li.highlighted {
  background: var(--corp-select-option-focus-background);
  color: var(--corp-select-option-focus-color);
}

.options-list li.selected.highlighted {
  background: var(--corp-select-option-selected-focus-background);
  color: var(--corp-select-option-selected-focus-color);
}
.options-list li:hover {
  background: var(--corp-select-option-focus-background);
  color: var(--corp-select-option-focus-color);
}
</style>
