import { ref, computed } from "vue";
import axios from "axios";
import api from "../utils/api";
import heic2any from "heic2any";

const uploadProgress = ref({}); // Store progress by noteId and fileId
const uploadStatus = ref({}); // Store status by noteId and fileId (e.g., 'uploading', 'completed', 'cancelled')
const uploadingFiles = ref({}); // Store files being uploaded for each note
const abortControllers = ref({});

export function useUploadStore() {
  // To handle cancellation for each file upload

  const chunkSize = 5 * 1024 * 1024; // 5MB

  const convertToJpg = async (file) => {
    if (file.type === "image/heic" || file.type === "image/heif") {
      const blob = await heic2any({ blob: file, toType: "image/jpeg" });
      return new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), {
        type: "image/jpeg",
      });
    }
    return file;
  };

  const initiateUpload = async (file) => {
    const response = await api.post("/api/files/initiate-upload", {
      fileName: file.name,
      fileType: file.type,
    });
    return response.data;
  };

  const uploadChunk = async (
    file,
    partNumber,
    uploadId,
    start,
    end,
    noteId,
    fileId
  ) => {
    const chunk = file.slice(start, end);

    const response = await api.get("/api/files/signed-url", {
      params: {
        fileName: file.name,
        fileType: file.type,
        partNumber,
        uploadId,
      },
    });

    const signedUrl = response.data.signedUrl;

    const controller = new AbortController();
    abortControllers.value[`${noteId}-${fileId}`] = controller;

    const uploadResponse = await axios.put(signedUrl, chunk, {
      headers: {
        "Content-Type": file.type,
      },
      signal: controller.signal,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (!uploadProgress.value[noteId]) {
          uploadProgress.value[noteId] = {};
        }
        uploadProgress.value[noteId][fileId] = progress;
      },
    });

    const etag = uploadResponse.headers.etag;

    return { ETag: etag, PartNumber: partNumber };
  };

  const completeUpload = async (fileName, uploadId, parts, fileId) => {
    return await api.post("/api/files/complete-upload", {
      fileName,
      uploadId,
      parts,
      fileId,
    });
  };

  const uploadFile = async (file, noteId = "new") => {
    const convertedFile = await convertToJpg(file);
    const { uploadId, fileId } = await initiateUpload(convertedFile, noteId);

    uploadStatus.value[`${noteId}-${fileId}`] = "uploading";

    // Add file to the uploading files list for the noteId
    if (!uploadingFiles.value[noteId]) {
      uploadingFiles.value[noteId] = [];
    }
    // = [
    //   ...uploadingFiles.value[noteId],
    //   {
    //     id: fileId,
    //     name: file.name,
    //     filePath: convertedFile.name,
    //   },
    // ];
    uploadingFiles.value[noteId].push({
      id: fileId,
      name: file.name,
      filePath: convertedFile.name,
    });

    const parts = [];

    for (let i = 0; i < convertedFile.size; i += chunkSize) {
      const partNumber = Math.floor(i / chunkSize) + 1;
      const part = await uploadChunk(
        convertedFile,
        partNumber,
        uploadId,
        i,
        i + chunkSize,
        noteId,
        fileId
      );
      parts.push(part);
    }

    const newFile = await completeUpload(
      convertedFile.name,
      uploadId,
      parts,
      fileId
    );
    delete uploadStatus.value[`${noteId}-${fileId}`];
    uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
      (n) => n.id !== fileId
    );
    return newFile.data;
  };

  const cancelUpload = (noteId = "new", fileId) => {
    const key = `${noteId}-${fileId}`;
    if (abortControllers.value[key]) {
      abortControllers.value[key].abort();
      delete abortControllers.value[key];

      // Remove file from the uploadingFiles list
      uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
        (file) => file.id !== fileId
      );
    }
  };

  // Function to get all uploading files for a specific noteId
  const getUploadingFilesByNoteId = (noteId = "new") =>
    computed(() => {
      console.log("getUploadingFilesByNoteId", noteId);
      return uploadingFiles.value[noteId] || [];
    });

  return {
    uploadFile,
    uploadProgress,
    uploadStatus,
    cancelUpload,
    getUploadingFilesByNoteId,
  };
}
