<template>
  <div
    class="icon"
    v-tooltip.bottom="!showCollaboratorMenu ? 'Collaborators' : null"
  >
    <i class="pi pi-user-plus" @click="toggleCollaboratorMenu" />
    <Teleport
      v-if="showCollaboratorMenu"
      :to="`#note-${noteId || 'new'} .menu-expand`"
    >
      <div class="collaborator-menu">
        <div class="flex align-items-center">
          Share this document
          <div class="flex-1" />
          <Button
            text
            icon="pi pi-times text-white"
            @click="toggleCollaboratorMenu"
          />
        </div>
        <!-- Conditional rendering: show either the share panel or the QR code panel -->
        <div v-if="!showQrCode">
          <div v-if="shareUrl > ''" class="field">
            <!-- Share this document URL -->
            <div class="p-inputgroup flex">
              <input
                id="document-url"
                type="text"
                class="p-inputtext w-full"
                :value="shareUrlComplete"
                readonly
              />
              <Button icon="pi pi-copy" @click="copyDocumentUrl" />
            </div>
            <div class="mt-2 flex justify-content-between">
              <Button
                v-if="canShare"
                label="Share Link"
                icon="pi pi-share-alt"
                class="p-button-primary"
                @click="shareDocument"
              />
              <Button
                label="QR Code"
                icon="pi pi-qrcode"
                class="p-button-secondary"
                @click="showQrCodePanel"
              />
            </div>
          </div>
        </div>

        <!-- QR Code Panel -->
        <div v-if="showQrCode" class="qr-code-panel">
          <VueQrcode value="https://storatic.com/register" class="qr-code" />
          <Button
            label="Done"
            icon="pi pi-check"
            class="w-full mt-3"
            @click="hideQrCodePanel"
          />
        </div>

        <h5 v-if="!showQrCode">Collaborators</h5>
        <div v-if="!showQrCode" class="p-fluid">
          <!-- Input row for user email and permissions -->
          <div class="field flex align-items-center">
            <AutoComplete
              v-model="selectedUser"
              :suggestions="userSuggestions"
              @input="searchUsersChange"
              placeholder="Search or add emails"
              optionLabel="email"
              class="w-full flex-1"
              inputClass="w-full"
              emptyMessage=""
            >
              <template #option="slotProps">
                <UserAutoCompleteItem :user="slotProps.option" />
              </template>
            </AutoComplete>
            <Dropdown
              v-model="selectedPermission"
              :options="permissionsOptions"
              optionLabel="label"
              optionValue="value"
              class="w-4"
              placeholder="Permission"
              appendTo="self"
            />
          </div>

          <Button
            label="Invite"
            severity="primary"
            class="mt-2 p-button-success flex-1"
            :disabled="submitting"
            @click="shareItemClick"
            v-if="
              selectedUser &&
              selectedPermission &&
              (validateEmail(selectedUser) || validateEmail(selectedUser.email))
            "
          />
          <div v-if="sharedUsers?.length > 0" class="shared-users">
            <hr />
            <h4>Shared With</h4>
            <ul>
              <li
                v-for="user in sharedUsers"
                :key="user.id"
                class="flex justify-between align-items-center"
              >
                <div class="flex gap-2 align-items-center">
                  <div
                    :style="{
                      backgroundImage: `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${user?.email?.charAt(
                        0
                      )}')`,
                    }"
                    alt="User Profile"
                    class="profile-pic"
                  />
                  <div class="flex-1 text-left">
                    {{ user.email }}

                    <div class="w-full">
                      <Dropdown
                        v-if="userCanEdit"
                        :modelValue="user.role"
                        :options="permissionsOptions"
                        optionLabel="label"
                        optionValue="value"
                        class="existing-role"
                        placeholder="Permission"
                        appendTo="self"
                        @change="
                          (e) =>
                            updateUserPermission({
                              ...user,
                              role: e.value,
                            })
                        "
                      />
                    </div>
                  </div>
                </div>

                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-text"
                  @click="removeUserShare(user, $event)"
                />
              </li>
            </ul>
          </div>
          <div v-else><h4>Not shared with anyone</h4></div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, toRefs, watch, nextTick } from "vue";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // Import QR Code component
import { debounce, validateEmail } from "../../utils/helpers";
import { useCollaboratorStore } from "../../hooks/useCollaboratorStore";
import UserAutoCompleteItem from "./UserAutoCompleteItem.vue";

// Emits definition
const emit = defineEmits(["opened", "closed", "change"]);

const showCollaboratorMenu = ref(false);
const submitting = ref(false);
const showQrCode = ref(false); // State to toggle between share URL and QR code view

const toggleCollaboratorMenu = (e) => {
  if (e) {
    e.colHandled = true;
  }
  showCollaboratorMenu.value = !showCollaboratorMenu.value;
};

const props = defineProps({
  itemType: String,
  noteId: [Number, String],
  shareUrl: String,
  ownerId: Number, // Owner of the item
});

const { noteId, shareUrl } = toRefs(props);
const {
  fetchUserSuggestions,
  sharedUsers,
  fetchSharedUsers,
  removeSharedUser,
  addSharedUser,
  updateSharedUser,
} = useCollaboratorStore(noteId.value);

const selectedUser = ref(null);
const selectedPermission = ref("view");
const userSuggestions = ref([]);
const permissionsOptions = ref([
  { label: "Viewer", value: "view" },
  { label: "Editor", value: "edit" },
]);
const userCanEdit = ref(true); // Add logic to determine if the current user can edit

const searchUsersChange = debounce(async (e) => {
  const results = await fetchUserSuggestions(e.target.value);
  userSuggestions.value = results;
}, 300);

// Check if the Web Share API is available
const canShare = ref(!!navigator.share);

const shareItemClick = async (event) => {
  if (event) {
    event.stopPropagation();
    event.colHandled;
  }

  const email = selectedUser.value?.email ?? selectedUser.value;
  if (selectedUser.value && selectedPermission.value) {
    submitting.value = true;
    await addSharedUser(noteId.value, {
      email: email,
      role: selectedPermission.value,
    });

    selectedUser.value = null;
    selectedPermission.value = null;
    await fetchSharedUsers(noteId.value);
    submitting.value = false;
  }
};

const removeUserShare = async (user, event) => {
  if (event) {
    event.stopPropagation();
    event.colHandled;
  }
  await removeSharedUser(noteId.value, user.id);
};

const updateUserPermission = async (user, event) => {
  if (event) {
    event.colHandled;
  }
  await updateSharedUser(noteId.value, user.id, { role: user.role });
};

const shareUrlComplete = `https://storatic.com/note/${noteId.value}/${shareUrl.value}`;

// Share document using the Web Share API
const shareDocument = async () => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: "Shared a note",
        url: shareUrlComplete,
      });
      console.log("Document shared successfully!");
    } catch (err) {
      console.error("Error sharing the document:", err);
    }
  } else {
    console.error("Web Share API not supported on this browser.");
  }
};

const copyDocumentUrl = () => {
  navigator.clipboard.writeText(shareUrlComplete);
};

// Handle clicks outside the popover to close it
const handleClickOutside = (event) => {
  if (!event || event.colHandled) {
    return;
  }
  if (!document.querySelector(".collaborator-menu")?.contains(event.target)) {
    toggleCollaboratorMenu();
  }
};

// Show the QR code panel
const showQrCodePanel = (e) => {
  e.stopPropagation();
  showQrCode.value = true;
};

// Hide the QR code panel and go back to the original view
const hideQrCodePanel = () => {
  showQrCode.value = false;
};

watch(showCollaboratorMenu, () => {
  if (showCollaboratorMenu.value) {
    const fn = async () => {
      await fetchSharedUsers(noteId.value);
    };
    nextTick(() => {
      emit("opened");
      document.addEventListener("click", handleClickOutside);
    });
    fn();
  } else {
    emit("closed");
    document.removeEventListener("click", handleClickOutside);
  }
});

watch(
  sharedUsers,
  () => {
    emit("change", sharedUsers.value);
  },
  { deep: true }
);
</script>

<style>
.icon {
  position: relative;
}

.collaborator-menu {
  position: absolute;
  background: var(--flyout-back);
  color: var(--corp-text-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
  width: 290px;
  max-width: 100vw;
  min-height: 200px;
  left: 0;
  top: -30px;
  max-height: 60vh;
  overflow-y: auto;
}
.collaborator-menu .p-select-label {
  padding: 0.5rem;
  text-align: left;
}
.collaborator-menu input {
  padding: 0.5rem 0.375rem;
}
.collaborator-menu .p-select-dropdown {
  width: 20px;
}
.collaborator-menu .p-select-dropdown .p-icon {
  width: 10px;
  height: 10px;
}
.collaborator-menu .existing-role {
  background: transparent;
  border: 0;
}
.collaborator-menu .existing-role .p-select-label {
  padding: 0;
}
</style>

<style scoped>
.shared-users {
  margin-top: 20px;
}

.shared-users ul {
  list-style: none;
  padding: 0;
}

.shared-users li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.shared-user-avatar,
.autocomplete-user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.qr-code-panel {
  text-align: center;
}
.qr-code {
  margin: 20px auto;
}
</style>
