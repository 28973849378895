<template>
  <div class="icon" v-tooltip.bottom="'More'">
    <i class="pi pi-ellipsis-v" @click="(e) => toggleMoreOptionsMenu(e)"></i>
    <div v-if="showMoreOptionsMenu" class="more-options-menu">
      <button>Add drawing</button>
      <button v-if="noteId" @click="deleteNote(noteId)">Archive</button>
      <!-- <button @click="showTasks">Show tasks</button> -->
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from "vue";
import { useNoteStore } from "../../hooks/useNoteStore";

const { deleteNote } = useNoteStore();
// eslint-disable-next-line no-unused-vars
const props = defineProps({ noteId: String });
const showMoreOptionsMenu = ref(false);

const toggleMoreOptionsMenu = (e) => {
  console.log("toggleMoreOptionsMenu");
  if (e) {
    e.moreHandled = true;
  }
  showMoreOptionsMenu.value = !showMoreOptionsMenu.value;
};
const handleClickOutside = (event) => {
  console.log("handleClickOutside", event?.moreHandled);
  if (event && event.moreHandled) {
    return;
  }
  if (
    showMoreOptionsMenu.value &&
    !document.querySelector(".more-options-menu")?.contains(event.target)
  ) {
    toggleMoreOptionsMenu();
  }
};

watch(showMoreOptionsMenu, () => {
  if (showMoreOptionsMenu.value === true) {
    document.addEventListener("click", handleClickOutside);
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
</script>

<style scoped>
.icon {
  position: relative;
}

.more-options-menu {
  position: absolute;
  background: var(--flyout-back);
  color: var(--corp-text-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 0;
  z-index: 1000;
  bottom: 0;
  right: 0;
}
.more-options-menu button {
  cursor: pointer;
  border: 0;
  padding: 0.5rem 0.75rem;
  background: none;
  white-space: nowrap;
  text-align: left;
  width: 100%;
  display: block;
}
</style>
