import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useLocationStore() {
  const isLoading = ref(false);
  const locations = computed(() => store.state.locations);

  async function fetchLocations(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/locations", { params: query });
      store.mutations.setState("locations", response.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createLocation(locationData) {
    try {
      const response = await api.post("/api/locations", locationData);
      store.mutations.addModel("locations", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating location:", error);
      throw error;
    }
  }

  async function updateLocation(id, locationData) {
    try {
      const response = await api.put(`/api/locations/${id}`, locationData);
      store.mutations.updateModel("locations", id, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating location:", error);
      throw error;
    }
  }

  async function deleteLocation(id) {
    try {
      await api.delete(`/api/locations/${id}`);
      store.mutations.removeModel("locations", id);
    } catch (error) {
      console.error("Error deleting location:", error);
      throw error;
    }
  }

  async function fetchLocationById(locationId) {
    try {
      const response = await api.get(`/api/locations/${locationId}`);
      store.mutations.addModel("locations", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching location by id:", error);
      throw error;
    }
  }

  async function searchLocations(query) {
    try {
      const response = await api.get("/api/locations", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching locations:", error);
      throw error;
    }
  }

  async function typeaheadLocations(query) {
    console.log(query);
    try {
      const response = await api.get(
        `/api/locations/typeahead?${new URLSearchParams({
          query: query,
        })}`
      );
      return response.data;
    } catch (error) {
      console.error("Error typeahead locations:", error);
      throw error;
    }
  }

  return {
    locations,
    isLoading,
    fetchLocations,
    createLocation,
    updateLocation,
    deleteLocation,
    fetchLocationById,
    searchLocations,
    typeaheadLocations,
  };
}
