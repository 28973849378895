import { ref, watch } from "vue";
import store from "../store";

const eventSource = ref(null);

export function useEventSource() {
  const isConnected = ref(false);

  const connectSSE = (url, authToken) => {
    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.body.getReader();
        } else {
          throw new Error("Failed to connect");
        }
      })
      .then((reader) => {
        const decoder = new TextDecoder();

        // Custom event source polyfill to handle streaming data
        const eventSourcePolyfill = {
          processChunk(chunk) {
            const events = chunk.split("\n\n");
            events.forEach((evt) => {
              if (evt.startsWith("data: ")) {
                const resdata = evt.replace("data: ", "");
                const { type, id, data, event } = JSON.parse(resdata);
                // Handle the event by updating the store based on the event type
                handleEvent(event, type, id, data);
              } else if (event === "event: open") {
                isConnected.value = true;
                console.log("SSE connection opened");
              } else if (event === "event: error") {
                console.error("SSE connection error");
                isConnected.value = false;
              }
            });
          },
        };

        const processStream = () => {
          reader.read().then(({ done, value }) => {
            if (done) return;
            eventSourcePolyfill.processChunk(
              decoder.decode(value, { stream: true })
            );
            processStream();
          });
        };

        processStream();
      })
      .catch((err) => {
        console.error("SSE connection error", err);
        isConnected.value = false;
      });
  };

  const handleEvent = (event, type, id, data) => {
    switch (event) {
      case "create":
        if (type === "notes") {
          console.log("handleEvent", event, type, id, data);
          store.mutations.addModel(type, data);
        }
        break;
      case "update":
        if (type === "notes") {
          console.log("handleEvent", event, type, id, data);
          let existingModel = store.state?.notes.find((n) => n.id === id);
          store.mutations.updateModel(type, id, { ...existingModel, ...data });
        }

        break;
      case "delete":
        if (type === "notes") {
          console.log("handleEvent", event, type, id, data);
          store.mutations.removeModel(type, id);
        }

        break;
      default:
        console.warn(`Unknown event type: ${event}`);
    }
  };

  watch(
    store.state.isAuthenticated,
    () => {
      if (store.state.isAuthenticated) {
        const { token } = store.state;
        if (!eventSource.value) {
          connectSSE(
            `${process.env.VUE_APP_API_URL || "http://localhost:3000"}/sse`,
            token
          );
        }
      } else {
        if (eventSource.value) {
          eventSource.value.close();
          eventSource.value = null;
        }
      }
    },
    { immediate: true, deep: true }
  );

  return { isConnected };
}
