<!-- src/pages/ForgotPasswordPage.vue -->
<template>
  <div class="flex justify-content-center align-items-center forgot-password">
    <div class="surface-card p-4 shadow-2 border-round text-left">
      <h2 class="text-center">Forgot Password</h2>
      <form @submit.prevent="submitEmail">
        <div class="field">
          <label for="email">Email</label>
          <InputText
            id="email"
            v-model="email"
            type="email"
            placeholder="Email"
            required
            class="w-full"
          />
        </div>
        <Button type="submit" label="Send Reset Link" class="w-full mt-3" />
      </form>
      <div v-if="message">{{ message }}</div>
      <router-link to="/login" class="block mt-3 text-center"
        >Back to Login</router-link
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { RouterLink } from "vue-router";
import api from "../utils/api.js";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
const email = ref("");
const message = ref("");

const submitEmail = async () => {
  try {
    const response = await api.post("/auth/forgot-password", {
      email: email.value,
    });
    message.value = response.data.message;
  } catch (error) {
    message.value = "An error occurred. Please try again later.";
  }
};
</script>

<style scoped>
.forgot-password {
  margin: auto;
  width: 290px;
}
.min-h-screen {
  min-height: 100vh;
}

.surface-card {
  background-color: white;
}
</style>
