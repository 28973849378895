import { computed } from "vue";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import store from "../store";
import api from "../utils/api";

export function useDeviceLocation() {
  let currentPollingInterval = 10 * 1000; // Start with 10 seconds
  const MIN_POLLING_INTERVAL = 5 * 1000; // Minimum interval: 5 seconds
  const MAX_POLLING_INTERVAL = 1 * 60 * 1000; // Maximum interval: 5 minutes
  const DISTANCE_THRESHOLD = 50; // Threshold distance in meters for considering significant movement

  let lastKnownPosition = null; // Store the last known position
  let pollingTimeoutId = null; // Store the current timeout ID to manage polling
  let isTracking = false; // State to track whether tracking is active
  let checkinAlerts = computed(() => store.state.checkinAlerts);

  async function getCurrentPosition() {
    if (Capacitor.isNativePlatform()) {
      return Geolocation.getCurrentPosition({ enableHighAccuracy: true });
    } else {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 30000,
            maximumAge: 0,
          });
        } else {
          reject(new Error("Geolocation is not supported by this browser."));
        }
      });
    }
  }

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371e3; // Radius of Earth in meters
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in meters
  }

  async function pollLocation() {
    if (!isTracking) return; // If tracking has been stopped, exit the function

    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;

      if (lastKnownPosition) {
        const distanceMoved = calculateDistance(
          lastKnownPosition.latitude,
          lastKnownPosition.longitude,
          latitude,
          longitude
        );

        if (distanceMoved > DISTANCE_THRESHOLD) {
          // Significant movement detected, reset polling interval
          currentPollingInterval = MIN_POLLING_INTERVAL;
        } else {
          // No significant movement, back off polling interval
          currentPollingInterval = Math.min(
            currentPollingInterval * 2,
            MAX_POLLING_INTERVAL
          );
        }
      }

      // Update last known position
      lastKnownPosition = { latitude, longitude };

      // Post the location data
      postLocation(latitude, longitude, generateDeviceId());

      // Continue polling with adjusted interval
      pollingTimeoutId = setTimeout(pollLocation, currentPollingInterval);
    } catch (error) {
      console.error("Error obtaining location:", error);
    }
  }

  async function startLocationTracking() {
    if (isTracking) return; // If tracking is already active, do nothing

    isTracking = true; // Set tracking to active
    await pollLocation(); // Start the initial polling
  }

  function stopLocationTracking() {
    if (!isTracking) return; // If tracking is not active, do nothing

    isTracking = false; // Set tracking to inactive
    if (pollingTimeoutId) {
      clearTimeout(pollingTimeoutId); // Clear the active polling timeout
    }
    pollingTimeoutId = null; // Reset the timeout ID
  }

  function generateDeviceId() {
    const key = "device-id";
    let deviceId = localStorage.getItem(key);

    if (!deviceId) {
      const userAgent = navigator.userAgent;
      const screenSize = `${screen.width}x${screen.height}`;
      const randomComponent = Math.random().toString(36).substring(2, 15);
      deviceId = btoa(`${userAgent}-${screenSize}-${randomComponent}`);
      localStorage.setItem(key, deviceId);
    }

    return deviceId;
  }

  async function postLocation(latitude, longitude, deviceId) {
    const data = {
      deviceId,
      latitude,
      longitude,
    };

    try {
      const response = await api.post("/api/checkin", data);
      console.log("Success:", response);
      store.mutations.setState("checkinAlerts", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  // Return functions to start and stop location tracking
  return {
    startLocationTracking,
    stopLocationTracking,
    isTracking,
    checkinAlerts,
  };
}
