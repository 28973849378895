<template>
  <div class="flex flex-column align-items-center p-4">
    <h2 class="text-center mb-4">Privacy Policy</h2>
    <div class="w-full md:w-8 surface-card p-4 shadow-2 border-round">
      <h3>Effective Date: 09/01/2024</h3>

      <h4>Introduction</h4>
      <p>
        Storatic values your privacy and is committed to protecting your
        personal information. This Privacy Policy outlines how we collect, use,
        and safeguard your information when you use Storatic services, including
        our website at https://storatic.com, subdomains (e.g.,
        https://api.storatic.com and https://files.storatic.com), and our mobile
        applications.
      </p>

      <h4>1. Information We Collect</h4>
      <p>
        We collect personal information such as your name, email address, and
        password when you register for an account. We also collect and store any
        notes, tasks, files, and other content you upload to Storatic. If you
        use location-based reminders, we collect your device’s last known
        geo-coordinates to provide these features.
      </p>

      <h4>2. How We Use Your Information</h4>
      <p>
        We use your personal data to provide the features and functionality of
        Storatic, including note-taking, task management, and file storage. We
        also use your information to ensure account security, prevent fraud, and
        provide location-based reminders when enabled. Additionally, anonymized
        data may be used to improve the app’s performance.
      </p>

      <h4>3. How We Share Your Information</h4>
      <p>
        We may share your information with third-party service providers (e.g.,
        AWS for file storage, LocationIQ for address typeahead) to facilitate
        the operation of Storatic. These providers are contractually obligated
        to protect your data and use it only for the purposes specified. We may
        disclose your personal data if required to do so by law or in response
        to a court order.
      </p>

      <h4>4. Data Security</h4>
      <p>
        We implement industry-standard security measures, including encryption
        (in transit and at rest), to protect your data from unauthorized access.
        However, no security system is completely secure, and we cannot
        guarantee the absolute security of your data.
      </p>

      <h4>5. Data Retention</h4>
      <p>
        Your data will be retained as long as your account is active. If you
        delete your account or specific content, the data will be permanently
        removed from our servers. Accounts that remain inactive for 12 months
        may be subject to deletion after providing a notice.
      </p>

      <h4>6. Cookies and Tracking Technologies</h4>
      <p>
        Storatic does not use cookies for tracking or analytics purposes.
        However, location data is stored when users enable location-based
        reminders.
      </p>

      <h4>7. Third-Party Services</h4>
      <p>
        Storatic uses third-party services such as AWS for file storage and
        LocationIQ for address typeahead. You can review their respective
        privacy policies at [AWS Privacy](https://aws.amazon.com/privacy) and
        [LocationIQ Privacy Policy](https://us1.locationiq.com/privacy).
      </p>

      <h4>8. Children’s Privacy</h4>
      <p>
        Storatic is not intended for use by children under 13, and we do not
        knowingly collect personal information from children. If we become aware
        that we have collected personal data from a child under 13, we will
        delete such information promptly.
      </p>

      <h4>9. Your Rights</h4>
      <p>
        You have the right to access the personal data we hold about you. You
        can update or correct your personal data at any time via your account
        settings. You can also request the deletion of your account and personal
        data at any time, and once deleted, this data cannot be recovered. You
        can disable location-based services by adjusting your device settings.
      </p>

      <h4>10. Changes to This Privacy Policy</h4>
      <p>
        We may update this Privacy Policy periodically. If significant changes
        are made, we will notify you through email or the app. Continued use of
        the Services after changes take effect constitutes your acceptance of
        the updated policy.
      </p>

      <h4>11. Contact Information</h4>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at support@storatic.com.
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useSidebarState } from "../hooks/useSidebarState";
const { isSidebarOpen, toggleSidebar } = useSidebarState();

let wasOpened = false;
onMounted(() => {
  wasOpened = isSidebarOpen.value;
  if (wasOpened) {
    toggleSidebar();
  }
});
onUnmounted(() => {
  if (wasOpened && !isSidebarOpen.value) {
    toggleSidebar();
  }
});
</script>

<style scoped>
.shadow-2 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.border-round {
  border-radius: 0.5rem;
}

h2,
h3,
h4 {
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.5;
}
</style>
