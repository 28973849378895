<template>
  <div class="flex gap-1 fixed top-0 right-0 actions">
    <RouterLink to="/login">
      <Button label="Login" severity="primary" outlined />
    </RouterLink>
    <RouterLink to="/register">
      <Button label="Register" />
    </RouterLink>
  </div>
  <NotePage
    v-if="id"
    :id="id"
    :token="token"
    :preview="true"
    :readOnly="true"
  />
</template>
<script setup>
import { computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import Button from "primevue/button";
import NotePage from "./NotePage";

const route = useRoute();

const id = computed(() => route.params.id);
const token = computed(() => route.params.token);
</script>

<style scoped>
.actions {
  z-index: 10;
  padding-top: 4px;
  padding-right: 25px;
}
</style>
