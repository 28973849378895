<template>
  <div class="photo-editor">
    <div ref="imageContainer">
      <img :src="file.signedUrl" ref="image" />
    </div>
    <div class="controls">
      <Button label="Rotate" @click="rotateImage" />
      <Button label="Crop" @click="cropImage" />
      <Button label="Save" @click="saveEdits" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";
import Button from "primevue/button";
// import Dialog from "primevue/dialog";
import Cropper from "cropperjs";

const props = defineProps({
  file: Object,
});
const emit = defineEmits(["close", "save"]);
const image = ref(null);
let cropper;

onMounted(() => {
  cropper = new Cropper(image.value, {
    aspectRatio: 1,
    viewMode: 2,
  });
});

const rotateImage = () => {
  cropper.rotate(90);
};

const cropImage = () => {
  cropper.crop();
};

const saveEdits = () => {
  const canvas = cropper.getCroppedCanvas();
  canvas.toBlob((blob) => {
    const newFile = new File([blob], props.file.name, { type: "image/jpeg" });
    emit("save", newFile); // Emit the edited file
  });
};
</script>

<style scoped>
.photo-editor {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}
.imageContainer img {
  max-height: 80vh;
}
.controls {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
</style>
