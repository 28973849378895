<template>
  <div class="flex gap-2 align-items-center">
    <div
      :style="{
        backgroundImage:
          user?.profilePicture ||
          `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${user?.email?.charAt(
            0
          )}')`,
      }"
      alt="User Profile"
      class="profile-pic"
    />
    <span>{{ user.email }}</span>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
});
const user = computed(() => props.user);
</script>

<style scoped>
.autocomplete-user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
</style>
