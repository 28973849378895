import { nextTick, toRefs } from "vue";
import { debounce } from "@/utils/helpers";
import { useTaskStore } from "../../../hooks/useTaskStore";

export function useTasks(props, onChange) {
  const { tasks, noteId } = toRefs(props);
  const { createTask, updateTask, deleteTask } = useTaskStore(); // Assuming you have an API method to save the task

  const debounceCb = async (task) => {
    if (task.isTmp) {
      let tmp = { ...task };
      delete tmp.id;
      let newTask = { ...task };

      newTask = await createTask(tmp);

      const index = tasks.value?.map((t) => t.id).indexOf(task.id);
      tasks.value[index] = newTask;
      onChange(tasks.value);
      nextTick(() => {
        focusLastTask(2);
      });
    } else {
      await updateTask(task.id, task);
    }
  };
  // Debounced save function
  const debouncedSaveTask = debounce((task) => {
    if (noteId.value) debounceCb(task);
  }, 700); // 500ms debounce delay

  const addTask = (index) => {
    const val = tasks.value[index].description;
    if (val.length > 0 && index === tasks.value.length - 1) {
      tasks.value.push({
        id: `TMP_NEW-${Date.now()}`,
        description: "",
        completed: false,
        order: tasks.value.length,
        showMenu: false,
        isTmp: true,
        noteId: noteId.value,
      });
      nextTick(() => {
        focusLastTask(2);
      });
      onChange(tasks.value);
    }
  };

  const removeTask = async (index) => {
    if (!tasks.value[index].isTmp) {
      await deleteTask(tasks.value[index].id, tasks.value[index].noteId);
    }
    tasks.value.splice(index, 1);
    onChange(tasks.value);
  };

  const handleTaskEnter = (index) => {
    if (index !== tasks.value.length - 1) {
      if (tasks.value[index]?.description?.trim() > "") {
        tasks.value.splice(index + 1, 0, {
          id: `TMP_NEW-${Date.now()}`,
          description: "",
          completed: false,
          order: index + 1,
          showMenu: false,
          isTmp: true,
          noteId: noteId.value,
        });
        nextTick(() => {
          focusTask(index + 1);
        });
        onChange(tasks.value);
      }
    }
  };

  const handleTaskDelete = async (index, e) => {
    let prevVal = tasks.value[index].description;
    if (prevVal.length === 0) {
      e?.preventDefault();
      e?.stopPropagation();
    }
    if (index !== tasks.value.length - 1) {
      if (prevVal?.trim() === "") {
        if (!tasks.value[index].isTmp) {
          await deleteTask(tasks.value[index].id);
        }
        removeTask(index);
        onChange(tasks.value);
        nextTick(() => {
          let previousIndex = index - 1;
          if (previousIndex < 0) {
            previousIndex = 0;
          }
          focusTask(previousIndex);
        });
      }
    }
  };

  const focusLastTask = (offset = 1) => {
    const idx = tasks.value.length === 1 ? 0 : tasks.value.length - offset;
    const lastTaskInput = document.querySelectorAll(
      `#${noteId.value ? `note-${noteId.value}` : "new"} .task-input`
    )[idx];
    if (lastTaskInput) lastTaskInput.focus();
  };

  const focusTask = (index) => {
    const taskInput = document.querySelectorAll(
      `#${noteId.value ? `note-${noteId.value}` : "new"} .task-input`
    )[index];
    if (taskInput) {
      taskInput.focus();
    }
  };

  const updateTaskFn = (index, value) => {
    tasks.value[index] = value;
    onChange(tasks.value);
    debouncedSaveTask(tasks.value[index]); // Save the task to the API with debounce
  };

  const toggleTaskMenu = (index) => {
    tasks.value[index].showMenu = !tasks.value[index].showMenu;
  };

  const assignResponsible = (task) => {
    // Logic for assigning responsibility
    task.showMenu = false;
  };

  const addDueDate = (task) => {
    // Logic for adding due date
    task.showMenu = false;
  };

  return {
    tasks,
    addTask,
    removeTask,
    handleTaskEnter,
    handleTaskDelete,
    focusLastTask,
    focusTask,
    updateTask: updateTaskFn,
    toggleTaskMenu,
    assignResponsible,
    addDueDate,
  };
}
