// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeWrap from "../pages/HomeWrap.vue";
import PreviewPage from "../pages/PreviewPage.vue";
import LoginPage from "../pages/LoginPage.vue";
import RegistrationPage from "../pages/RegistrationPage.vue";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/ResetPasswordPage.vue";
import ChangePasswordPage from "../pages/ChangePasswordPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import TermsOfUsePage from "../pages/TermsOfUsePage.vue";
import PrivacyPage from "../pages/PrivacyPage.vue";
import AdminPage from "../pages/AdminPage.vue";
import VerifyEmail from "../pages/VerifyEmail.vue";

import store from "../store";

const routes = [
  {
    path: "/note/:id",
    component: HomeWrap,
    meta: { requiresAuth: true, show: true },
  },
  {
    path: "/note/:id/:token",
    component: PreviewPage,
    meta: { requiresAuth: false, show: true },
  },
  {
    path: "/preview/:id",
    component: HomeWrap,
    meta: { requiresAuth: true, preview: true },
  },
  { path: "/", component: HomeWrap, meta: {} },
  { path: "/login", component: LoginPage },
  { path: "/profile", component: ProfilePage, meta: { requiresAuth: true } },
  {
    path: "/profile/edit",
    component: ProfilePage,
    meta: { requiresArequiresAuthdmin: true, edit: true },
  },
  { path: "/register", component: RegistrationPage },
  { path: "/forgot-password", component: ForgotPasswordPage },
  {
    path: "/change-password",
    component: ChangePasswordPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordPage,
    meta: { requiresAuth: true },
  },
  { path: "/admin", component: AdminPage, meta: { requiresAdmin: true } },
  // { path: "/public", component: InvitationPage },
  {
    path: "/verify-email",
    component: VerifyEmail,
  },
  {
    path: "/terms-of-service",
    component: TermsOfUsePage,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPage,
  },
  // {
  //   path: "/editor",
  //   component: Editor,
  // },
];

const router = createRouter({
  linkActiveClass: "active-nav",
  linkExactActiveClass: "exact-active-nav",
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("store", store);
  const user = store.state.user; // Assuming you have user info in a global store
  if (to.meta.requiresAuth && !user) {
    next(`/login?return_url=${to.path}`);
  } else if (to.meta.requiresAdmin && (!user || user.roleId !== 1)) {
    next(`/login?return_url=${to.path}`);
  } else {
    next();
  }
});

export default router;
