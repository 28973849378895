<template>
  <div class="label-icon">
    <LabelIcon @click="toggleLabelMenu" v-tooltip.bottom="'Labels'" />
    <Teleport
      v-if="showLabelMenu"
      :to="`#note-${noteId || 'new'} .menu-expand`"
    >
      <div class="label-menu">
        <div class="header">
          Labels
          <div class="flex-1" />
          <Button text icon="pi pi-times text-white" @click="toggleLabelMenu" />
        </div>
        <div class="input-wrap">
          <div class="label-input-wrap">
            <input
              type="text"
              placeholder="Enter label name"
              v-model="searchTerm"
              @input="filterLabels"
              @keydown.enter="tryToSubmit"
              class="label-input"
              autoFocus
            />
          </div>
          <ul class="label-list">
            <li
              v-for="label in filteredLabels"
              :key="label.id"
              @click="toggleLabel(label, noteId)"
              class="label-item"
            >
              <input type="checkbox" :checked="isSelected(label)" />
              <span>{{ label.name }}</span>
            </li>
          </ul>
        </div>
        <div
          v-if="canCreateLabel"
          @click="createLabel({ name: searchTerm, noteId }, $event)"
          class="create-label"
        >
          <span>+ Create "{{ searchTerm }}"</span>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, nextTick, toRefs } from "vue";
import Button from "primevue/button";
import { useLabels } from "./hooks/useLabels"; // Import the hook
import LabelIcon from "./LabelIcon.vue"; // The icon component for opening the label menu
const emit = defineEmits(["update:selectedLabels"]);
const props = defineProps({
  noteId: String,
  selectedLabels: Array,
});
const { selectedLabels } = toRefs(props);
const {
  searchTerm,
  showLabelMenu,
  filteredLabels,
  toggleLabelMenu,
  filterLabels,
  isSelected,
  toggleLabel,
  canCreateLabel,
  createLabel,
} = useLabels(selectedLabels, (val) => {
  emit("update:selectedLabels", val);
});

watch(showLabelMenu, () => {
  if (showLabelMenu.value) {
    nextTick(() => {
      document.querySelector(".label-input")?.focus();
    });
  }
});
const tryToSubmit = () => {
  console.log("tryToSubmit");
  if (canCreateLabel.value) {
    createLabel({ name: searchTerm.value, noteId: props.noteId });
  }
};
</script>

<style scoped>
.label-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-menu {
  position: absolute;
  right: 20px;
  top: -30px;
  background: var(--flyout-back);
  color: var(--corp-text-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  z-index: 1000;
  width: 200px;
  display: flex;
  flex-direction: column;
  height: 200px;
}
.note .input-wrap {
  background: var(--flyout-back);
  padding: 0 0.5rem 0.5rem;
  overflow-y: auto;
}
.note .header {
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: var(--flyout-back);
}
.note .label-input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: var(--corp-inputtext-background) !important;
}
.note .label-input-wrap {
  position: sticky;
  top: 0;
  background: var(--flyout-back);
  padding: 0 8px 8px;
}

.label-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  flex: 1;
}

.label-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
}

.create-label {
  cursor: pointer;
  color: #007bff;
  font-weight: 700;
  white-space: nowrap;
  text-align: left;
  padding: 0.5rem;
  position: sticky;
  bottom: 0px;
  background: var(--flyout-back-alt);
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
