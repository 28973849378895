import { RRule } from "rrule";
import { useRouter } from "vue-router";
import { format } from "date-fns";

export const timezoneDate = (date) => {
  const selectedDate = new Date(date);

  // Assuming you want to include the user's local timezone offset
  const timezoneOffset = selectedDate.getTimezoneOffset(); // In minutes
  const timezoneOffsetInHours = timezoneOffset / 60; // Convert to hours

  console.log("Selected Date:", selectedDate);
  console.log("Timezone Offset (in hours):", timezoneOffsetInHours);

  // If you need to send the date with timezone information to the server:
  const isoString = selectedDate.toISOString(); // This includes timezone as 'Z' (UTC)
  console.log("ISO String with UTC timezone:", isoString);

  // You can format it as needed, e.g., sending a formatted string with timezone info to the server
  const formattedDateWithTimezone = `${
    selectedDate.toISOString().split("T")[0]
  }T00:00:00${timezoneOffsetInHours > 0 ? "-" : "+"}${String(
    Math.abs(timezoneOffsetInHours)
  ).padStart(2, "0")}:00`;
  return formattedDateWithTimezone;
};

export const handleChipsBlur = (event, model, field) => {
  const inputElement = event.target;
  const inputValue = inputElement.value.trim();

  if (inputValue) {
    if (!model[field]) {
      model[field] = [];
    }
    model[field].push(inputValue);
    inputElement.value = ""; // Clear the input field after adding the chip
  }
};

export const debounce = (fn, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
export function dateToShortUrlFormat(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function shortUrlFormatToDate(shortDate) {
  const [year, month, day] = shortDate.split("-").map(Number);
  return new Date(year, month - 1, day); // Months are zero-indexed
}

export function generateRRule(options) {
  const freqMap = {
    1: RRule.DAILY, // 1: Daily
    2: RRule.WEEKLY, // 2: Weekly
    3: RRule.MONTHLY, // 3: Monthly
    4: RRule.YEARLY, // 4: Yearly
  };

  const rruleOptions = {
    freq: freqMap[options.frequency], // Use frequency from model
    interval: options.interval,
  };

  if (options.endCondition === "after") {
    rruleOptions.count = options.endAfterOccurrences;
  } else if (options.endCondition === "on") {
    rruleOptions.until = options.endOnDate;
  }

  // Handle specific day of the week for weekly frequency
  if (options.frequency === 2 && options.weekday) {
    rruleOptions.byweekday = options.weekday; // Assuming this is an array of weekdays (e.g., [RRule.MO, RRule.WE])
  }

  // Handle nth weekday (e.g., "3rd Tuesday of the month")
  if (options.frequency === 3 && options.nthWeekday && options.weekday) {
    rruleOptions.byweekday = [
      RRule.weekday(options.weekday).nth(options.nthWeekday),
    ]; // For example, nth(3) would be the 3rd occurrence of the specified weekday
  }

  // Handle specific day of the month for monthly frequency
  if (options.frequency === 3 && options.dayOfMonth) {
    rruleOptions.bymonthday = options.dayOfMonth;
  }

  // Handle specific month for yearly frequency
  if (options.frequency === 4 && options.monthOfYear) {
    rruleOptions.bymonth = options.monthOfYear;
  }

  const rule = new RRule(rruleOptions);
  return rule;
}

export function getRRuleText(rule) {
  return rule.toText();
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getReminderDisplay = (reminder) => {
  let ret = "";
  if (reminder.locationId) {
    ret = `${
      reminder.locationTriggerType === "arrive" ? "Arrive" : "Leave"
    } - ${reminder.location?.address.split(",", 5).join(" ")}`;
  } else {
    let rule;
    if (reminder.event?.frequency > 0) {
      rule = generateRRule({
        frequency: reminder.event?.frequency,
        interval: reminder.event?.interval,
        endAfterOccurrences: reminder.event?.count,
        endOnDate: reminder.event?.repeatEnd,
      });
    }

    ret = `${format(reminder.event?.startTime, "E MMM dd,yy hh:mm aa")} ${
      rule ? getRRuleText(rule) : ""
    }`;
  }
  return ret;
};

export const safeBackRoute = () => {
  const router = useRouter();
  const historyState = router?.options?.history?.state;
  if (historyState) {
    return historyState.back;
  }
  return "/";
};

export const isMobile = window.innerWidth <= 460;
