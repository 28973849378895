<template>
  <Dialog
    header="Two-Factor Authentication"
    :visible="true"
    @modelUpdate:visible="
      (v) => {
        if (!v) {
          emit('dismiss');
        }
      }
    "
    modal
    class="w-10 sm:w-6 md:w-4"
  >
    <div class="p-fluid">
      <p>Please enter your Two-Factor Authentication code:</p>

      <div class="field">
        <label for="twoFactorCode">2FA Code</label>
        <InputText
          id="twoFactorCode"
          v-model="twoFactorCode"
          placeholder="Enter 2FA code"
          required
          class="w-full"
        />
      </div>

      <div class="mt-4 flex justify-content-end">
        <Button label="Submit" icon="pi pi-check" @click="submitCode" />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

const emit = defineEmits(["onClose", "dismiss"]); // Event emitted after submitting 2FA code

const twoFactorCode = ref(""); // Two-factor code input

const submitCode = () => {
  emit("onClose", twoFactorCode.value); // Emit the event with the 2FA code
};
</script>

<style scoped>
/* Add any necessary styling here */
</style>
